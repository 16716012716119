<template>
	<div style="background: rgba(247, 247, 247, 1);padding-right: 40rem">
		<div class="title" style="position: relative">
      <img class="bg" src="../../../../assets/img/fresh-registration-details-bg.png" alt="" />
			<div class="title-title">
				<div style="margin-top: 19rem;margin-left: 24rem">基础信息</div>
			</div>
			<div class="title-bottom">
				<div style="display: flex;min-height: 50rem;align-items: end">
					<Item :value="form.base_info.mobile" label="联系电话"></Item>
					<Item :value="form.base_info.name" label="联系人姓名"></Item>
					<Item :value="form.base_info.identification_text" label="联系人身份"></Item>
					<Item :value="form.base_info.belong_teacher" label="客户归属人"></Item>
				</div>
				<div style="display: flex;min-height: 50rem;align-items: end">
					<Item :value="form.base_info.school_name" label="咨询学校"></Item>
					<Item :value="form.base_info.recruit_status === 1 ? '可招收' : '不可招收'" label="是否可招收"></Item>
					<Item label="" value=""></Item>
					<Item label="" value=""></Item>
				</div>
				<div v-if="form.base_info.recruit_status === 0" style="display: flex;min-height: 50rem;align-items: end">
					<Item v-if="form.base_info.recruit_status === 0" :value="form.base_info.recruit_status_text" label="不可招收原因"></Item>
				</div>
			</div>
		</div>


		<div class="title">
			<div class="title-title">
				<div style="margin-top: 19rem;margin-left: 24rem">客户资料</div>
			</div>
			<div class="title-bottom">
				<div style="display: flex;min-height: 50rem;align-items: end">
					<Item :value=" form.customer.parent_name" label="监护人姓名"></Item>
					<Item :value="form.customer.relationship" label="监护人和学生的关系" label-width="140rem"></Item>
					<Item label="监护人电话">
						<div v-if="!modifyStatus" style="width: 120rem;font-size: 14rem !important;font-weight: 400;		color: rgba(0, 0, 0, 0.6);">{{ form.customer.parent_contact }}</div>
						<el-input v-else v-model="form.customer.parent_contact" maxlength="11" size="mini" style="width: 120rem;margin-right: 24rem"></el-input>
						<el-button size="mini" @click="onModifyStatus">{{ modifyStatus ? '保存' : '修改' }}</el-button>
					</Item>
					<Item label="" value=""></Item>
				</div>
				<div style="display: flex;min-height: 50rem;align-items: end">
					<Item :value="form.customer.student_name" label="学生姓名"></Item>
					<Item :value="form.customer.student_sex===1?'男':'女'" label="学生性别"></Item>
					<Item :value="form.customer.student_nation_text" label="学生民族"></Item>
					<Item :value="form.customer.student_age" label="学生年龄"></Item>
				</div>
			</div>
		</div>

		<div class="title">
			<div class="title-title">
				<div style="margin-top: 19rem;margin-left: 24rem">学生历史学习情况</div>
			</div>
			<div class="title-bottom">
				<div style="display: flex;min-height: 50rem;align-items: end">
					<Item :value="form.history.student_attend_road" label="就读途径"></Item>
				</div>
				<div style="display: flex;min-height: 50rem;padding-top: 31rem;box-sizing: border-box">
					<Item :value=" form.history.area" label="高考考试区域"></Item>
					<Item :value="form.history.student_senior_achievement" label="高考预估成绩总分" label-width="140rem"></Item>
					<Item :value="form.history.student_attend_graduation_school" label="学生高中毕业学校" label-width="140rem"></Item>
					<Item :value="form.history.student_attend_graduation_class" label="毕业班级"></Item>
				</div>
				<div style="display: flex;min-height: 50rem;align-items: end">
					<Item :value="form.history.student_high_languages" label="高中学习外语语种" label-width="140rem"></Item>
					<Item :value="form.history.high_examination_type" label="高中考试类型"></Item>
					<Item :value="form.history.student_high_direction" label="高中学习方向"></Item>
					<Item label="" value=""></Item>
				</div>
			</div>
		</div>

		<div class="body">
			<div class="body-title">备注记录</div>
			<div class="body-bottom" style="padding-top: 28rem">
				<el-table :data="form.remark" :header-cell-style="{background: '#F0F7FF'}" :stripe="false" style="width: 100%">
					<el-table-column v-for="item in tableConfig" :label="item.label" :prop="item.prop" :width="item.width"/>
				</el-table>
			</div>
		</div>

		<div class="button" style="margin-top: 76rem">
			<el-button @click="$router.back()">返回</el-button>
			<el-button style="margin-left: 32rem" type="primary" @click="addNotes">添加备注</el-button>
		</div>

		<el-dialog :visible.sync="remarkVisible" custom-class="remark-box" title="添加备注" width="850rem" @close="remarkClose">
			<div>
				<el-input v-model="remark" maxlength="500" placeholder="请输入" resize="none" rows="8" show-word-limit type="textarea"></el-input>
			</div>
			<div>
			</div>
			<span slot="footer" class="dialog-footer">
        <el-button @click="()=>remarkVisible=false">取消</el-button>
        <el-button :disabled="!remark" type="primary" @click="remarkHandle">保存</el-button>
      </span>
		</el-dialog>
	</div>
</template>
<script>

import Item from "@/views/finance/enrollment/payment-progress/components/Item.vue";
import ListTemplate from "@/components/pages/List.vue"

export default {
	_config:{"route":{"path":"look","meta":{"title":"查看"}}},
  components: {ListTemplate, Item},
	data() {
		return {
			OSS_URL: process.env["VUE_APP_URL_OSS"],
			tableConfig: [
				{prop: 'remark', label: '备注内容', showOverflowTooltip: false},
				{prop: 'creater_name', label: '创建人'},
				{prop: 'created_at', label: '创建时间'},
			],
			form: {
				base_info: {},
				customer: {},
				history: {},
				remark: [],
			},
			remarkVisible: false,
			remark: '',
			modifyStatus: 0,
		}
	},
	methods: {
		addNotes() {
			this.remarkVisible = true
		},
		remarkClose() {
			this.remark = ''
		},
		remarkHandle() {
			this.$_register.post('api/recruit-v2/account-manage/add-remark', {id: this.$route.query.id, remark: this.remark}).then(res => {
				this.$message.success('添加成功')
				this.remarkVisible = false
				this.$_register.get('api/recruit-v2/account-manage/detail?id=' + this.$route.query.id).then(res => {
					this.form = res.data.data;
				})
			})
		},
		onModifyStatus() {
			if (this.modifyStatus) {
				this.$_register.post('api/recruit-v2/account-manage/edit-mobile', {id: this.$route.query.id, mobile: this.form.customer.parent_contact}).then(res => {
					this.modifyStatus = 0
					this.$message.success('修改成功')
				})
			} else {
				this.modifyStatus = 1
			}
		},
	},

	created() {
		this.$_register.get('api/recruit-v2/account-manage/detail?id=' + this.$route.query.id).then(res => {
			this.form = res.data.data;
		})
	}
}
</script>
<style lang="scss" scoped>
.title-title {
	font-size: 18rem;
	font-weight: bold;
	display: flex;
	justify-content: space-between;
	box-sizing: border-box;
	height: 60rem;
	color: rgba(0, 0, 0, 0.8);
	border-bottom: 1rem solid #F7F7F7;
	border-radius: 10rem 10rem 0 0;
	background: #FFF;
	box-shadow: 2rem 2rem 2rem 1rem rgba(232, 237, 255, 0.16);
}

.title-bottom {
	box-sizing: border-box;
	margin-bottom: 24rem;
	//padding-top: 28rem;
	padding-bottom: 24rem;
	padding-left: 32rem;
	border-radius: 0 0 10rem 10rem;
	background: #FFF;
}

.body {
	margin: 24rem 0;

	& .body-title {
		font-size: 18rem;
		font-weight: bold;
		box-sizing: border-box;
		height: 78rem;
		padding-top: 19rem;
		padding-left: 24rem;
		color: rgba(0, 0, 0, 0.8);
		border-bottom: 1rem solid #F7F7F7;
		border-radius: 10rem 10rem 0 0;
		background: #FFFFFF;
		box-shadow: 2rem 2rem 2rem 1rem rgba(232, 237, 255, 0.16);
	}

	& .body-bottom {
		box-sizing: border-box;
		min-height: 148rem;
		padding: 0 24rem 24rem;
		border-radius: 0 0 10rem 10rem;
		background: #FFFFFF;
		box-shadow: 2rem 2rem 2rem 1rem rgba(232, 237, 255, 0.16);

		& .body-bottom-label {
			font-family: Microsoft YaHei-Regular, Microsoft YaHei, serif;
			font-size: 14rem;
			font-weight: 400;
			width: 96rem;
			color: rgba(0, 0, 0, 0.8);
		}
	}
}

.el-table__body .cell {
	white-space: normal;
	word-wrap: break-word;
}

.bg {
	position: absolute;
	top: 0;
	right: 0;
	width: 220rem;
	height: 60rem;
}
</style>
